
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import { useStore } from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import store from "@/store";

interface CardData {
  name: string;
  email: string;
  phone: string;
  password: string;
  referal: string;
}

export default defineComponent({
  name: "new-partner-adding-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);
    const refNewPartnerForm = ref<null | HTMLFormElement>(null),
        refButtonResetForm = ref<null | HTMLElement>(null);

    const cardData = ref<CardData>({
      name: "",
      email: "",
      phone: "",
      password: "",
      referal: "",
    });

    const validationSchema = Yup.object().shape({
/*      name: Yup.string().required().label("Name"),*/

      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().required().label("Password"),
      phone: Yup.string()
          .required()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(11)
          .max(11)
          .label("Phone"),
    });

    onMounted(() => {
      console.log('Mounted')
    })

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let data = {
        email: cardData.value.email,
        phone: cardData.value.phone,
        fname: cardData.value.name,
        lname: "",
        refId: cardData.value.referal
      }

      if (submitButtonRef.value) {
        store
            .dispatch(Actions.ADD_NEW_PARTNER, data)
            .then(() => {
              store.dispatch(Actions.GET_PARTNERS_LIST)
              .then(() => {
                submitButtonRef.value!.disabled = false;
                submitButtonRef.value?.removeAttribute("data-kt-indicator");
                (document.getElementById('kt_modal_new_card_form') as HTMLFormElement).reset()
              })
            })
            .catch(() => {
              submitButtonRef.value!.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            })
      }

      Swal.fire({
        text: t("processDone"),
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: t("good"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(() => {
        hideModal(newCardModalRef.value);
      });

    };

    return {
      cardData,
      validationSchema,
      submit,
      submitButtonRef,
      newCardModalRef,
      refNewPartnerForm,
      t,
    };
  },
});
