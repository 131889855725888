
import {computed, defineComponent, onMounted, ref} from "vue";
import PartnersTable from "@/components/kt-datatable/PartnersTable.vue";
import NewPartnerAddingModal from "@/components/modals/forms/NewPartnerAddingModal.vue";
import UpdatePartnersStatisticModal from "@/components/modals/forms/UpdatePartnersStatisticModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "partners-listing",
  components: {
    PartnersTable,
    NewPartnerAddingModal,
    UpdatePartnersStatisticModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const loader = ref<HTMLElement | null>(null);
    let loading = ref(false);

    const partnersList = computed(() => {
      console.log('PARTNERS')
      return  store.getters.getterPartnersList
    });

    const currentRouteName = computed(() => {
      return route.name;
    });

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: t("firstName"),
        key: "name",
        sortable: true,
      },
      {
        name: t("email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("phoneNumber"),
        key: "phone",
        sortable: true,
      },
      {
        name: t("refId"),
        key: "refId",
        sortable: true,
      },
      {
        name: t("Subscriptions"),
        key: "subscriptions",
        sortable: true,
      },

      {
        name: t("actions"),
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      loading.value = false
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deletePartner(item);
      });
      checkedCustomers.value.length = 0;
    };

    const goDetails = (id) => {
      store.dispatch(Actions.FETCH_USER, {'id': id})
          .then(() => {
            router.push({ name: 'user', params: { id: id } })
          })
    };

    const partnersListSearched = computed(() => {
      return partnersList.value.filter(item =>
          searchingFunc(item, search.value)
      )
    })

    const deletePartner = (id: string) => {
      console.log('deletePartner ID - ', id)

      Swal.fire({
        title: "Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
          .then((result) => {
            if (result.isConfirmed) {

              loader.value?.setAttribute("data-kt-indicator", "on");
              loading.value = true

              //  Dummy timeout
              setTimeout(() => {
                store.dispatch(Actions.DELETE_PARTNER, id)
                    .then(() => {
                      store.dispatch(Actions.GET_PARTNERS_LIST)
                          .then(() => {
                            search.value = ""
                            loader.value?.removeAttribute("data-kt-indicator");
                            loading.value = false
                          })

                    })
              }, 1000)
            }
          })
          .catch(() => {
            Swal.fire({
              text: 'Error',
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try Again",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
    };

    const search = ref<string>("");

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };



    return {
      partnersList,
      partnersListSearched,
      currentRouteName,
      tableHeader,
      deletePartner,
      search,
      loader,
      loading,
      checkedCustomers,
      deleteFewCustomers,
      goDetails,
      t,
    };
  },
});
