<template>
    <PartnersListing />
</template>

<script>
import PartnersListing from '@/views/apps/customers/PartnersListing.vue'
import {Actions} from "../../store/enums/StoreEnums";
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "../../core/helpers/breadcrumb";

export default {
  name: "Subscribers",
  components: {
    PartnersListing
  },
  created() {
    // fetch all users from api
    // store.dispatch(Actions.FETCH_USERS)
  },
  mounted() {
    store.dispatch(Actions.GET_PARTNERS_LIST)
  },
  setup() {
    setCurrentPageBreadcrumbs("Partners", [] );
  }
}
</script>

<style scoped>

</style>