
import {defineComponent, ref, onMounted, computed} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal, modalHideEvent } from "@/core/helpers/dom";
import * as Yup from "yup";
import { useStore } from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import PartnersAnalyzeTable from "@/components/kt-datatable/PartnersAnalyzeTable.vue";
import File from "@/components/files/File.vue";
import axios from "axios";
import config from "@/core/config/APIConfig";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-partner-adding-modal",
  components: {
    Form,
    PartnersAnalyzeTable,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);
    const refFile = ref<null | HTMLInputElement>(null);
    let foundedUsers = ref<any>([]);
    let isSearched = ref<any>(null),
        errorMessage = ref(''),
        isError = ref(false),
        isFileChosen = ref(false);


    const tableHeader = ref([
      {
        name: "ID",
        key: "id"
      },
      {
        name: t("email"),
        key: "name",
      },
      {
        name: t("phone"),
        key: "phone",
      },
      {
        name: "Yandex ID",
        key: "yandexId",
      },
      {
        name: t("partnerReferral"),
        key: "partner",
      },
    ]);

    // const isFileChosen = computed(() => !!(refFile!.value!.files!))

    const handleFileUpload = (event: Event): void => {
      // console.log('event ', ((event!.target! as HTMLInputElement).files)!.length)
      isFileChosen.value = !!(refFile.value!.files!).length

      console.log('event ', !!(refFile.value!.files!).length)


      // isFileChosen.value = true

      // console.log(refFile?.value?.files?.length ? refFile?.value?.files[0] : '')
      // console.log((event.target as HTMLInputElement).files[0])
      /*if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files?.length) {
        const file = (event.target as HTMLInputElement).files
      }*/
    }

    onMounted(() => {
      modalHideEvent(newCardModalRef.value, () => {
        isSearched.value = null
        isError.value = false
      })
    })

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let formData = new FormData()

      formData.append('csvFile', refFile?.value?.files ? refFile?.value?.files[0] : '')

      if (submitButtonRef.value) {
        axios( config.routeAnalyzeCSV,
            {
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              method: "post",
            }
        )
            .then((res) => {
              submitButtonRef.value!.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
              refFile!.value!.value = ''
              isFileChosen.value = false

              if ( res.data.length ) {
                foundedUsers.value = res.data
                isSearched.value = true
              } else {
                foundedUsers.value = []
                isSearched.value = false
              }
              isError.value = false
              console.log(res.data)
            })
            .catch(({ response }) => {
              console.log(response)
              console.log('ANALYZE_CSV - ERROR!')
              submitButtonRef.value!.disabled = false;
              refFile!.value!.value = ''
              isSearched.value = null
              errorMessage.value = "Please try again later"
              isError.value = true
              isFileChosen.value = false

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            });

      }
    

    };

    return {
      refFile,
      foundedUsers,
      tableHeader,
      submitButtonRef,
      newCardModalRef,
      isSearched,
      errorMessage,
      isError,
      isFileChosen,
      submit,
      handleFileUpload,
      t,
    };
  },
});
